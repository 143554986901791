<template>
  <div class="component-container">
    <component :is="componentId" :pageData="pageData" @changePage="changePage"></component>
  </div>
</template>

<script>

export default {
  name: 'Notification',
  components: {
    NotificationTable: () => import('./pages/NotificationTable'),
    NotificationMng: () => import('./pages/NotificationMng'),
    NotificationDetail: () => import('./pages/NotificationDetail')
  },
  data() {
    return {
      componentId: 'NotificationTable',
      pageData: ''
    }
  },
  methods: {
    changePage(params) {
      this.pageData = params.pageData
      this.componentId = params.pageId
    }
  }
}
</script>
<style scoped lang="css">
.component-container{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
</style>
